import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SRLWrapper } from 'simple-react-lightbox';

export default function Arts({ data, location }) {
	const { allMarkdownRemark } = data;
	const edges = allMarkdownRemark.edges;
	const edge = edges[0];
	const galleryArt = edge.node.frontmatter.arts;
	const optionsLightbox = {
		caption: {
			showCaption: true,
		},
		buttons: {
			showDownloadButton: false,
		},
		thumbnails: {
			showThumbnails: false,
		},
	};

	return (
		<Layout location={location} lang="en">
			<Seo title="Art" />
			<div className="my-12 pt-12">
				<h2 className="text-center text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
					Choose from our gallery or we can customize it just for you.
				</h2>
			</div>
			<div className="mb-12 py-12">
				<SRLWrapper options={optionsLightbox}>
					<div className="grid grid-cols-1 md:grid-cols-4">
						{galleryArt.map((art, index) => (
							<div key={index} className="flex-1 bg-black cursor-pointer">
								<GatsbyImage
									className="hover:opacity-40"
									image={art.childImageSharp.gatsbyImageData}
									alt={art.name}
									style={{ height: '100%', width: '100%', marginBottom: 0 }}
									imgStyle={{ objectFit: 'inherit' }}
								/>
							</div>
						))}
					</div>
				</SRLWrapper>
			</div>
		</Layout>
	);
}

export const artQuery = graphql`
	query {
		allMarkdownRemark(
			filter: {
				html: { eq: "" }
				frontmatter: {
					products: { elemMatch: { sourceInstanceName: { ne: "products" } } }
				}
			}
		) {
			edges {
				node {
					frontmatter {
						title
						arts {
							name
							publicURL
							id
							childImageSharp {
								gatsbyImageData(formats: [AUTO, WEBP, AVIF])
							}
						}
					}
				}
			}
		}
	}
`;
